import React, { Componet } from "react";

class Faq extends React.Component {
  render() {
    return (
      <div>
        <div id="faq"></div>
        <section class="faq_sec sec-padding --xlarge bg-black">
          <div class="container">
            <div class="row">
              <div class="col-lg-2 hidden-lg-down"></div>
              <div class="col-lg-8">
                <h2 class="title">FAQs</h2>
                <div class="faq_listing">
                  <div class="acc">
                    <div class="acc__card">
                      <div class="acc__title">
                        Who Is Behind The Ethernal Gates NFT Project?{" "}
                      </div>
                      <div class="acc__panel">
                        The founders of Arts DAO and the Ethernal Gates NFT
                        project have been formed from a community comprising
                        artists, investors, art collectors and blockchain
                        developers that have come together to redefined asset
                        management in the metaverse and what it means to be part
                        of a community. The team is completely doxed, so you can
                        find information about the ArtsDAO team in{" "}
                        <a
                          href="https://discord.gg/ArtsDao"
                          class="fc-primary td-underline"
                        >
                          this link
                        </a>
                        .
                      </div>
                    </div>
                    <div class="acc__card">
                      <div class="acc__title">
                        Where Can I Buy The Ethernal Gates Art Piece?
                      </div>
                      <div class="acc__panel">
                        Minting will be available through our official website
                        and smart contract address only. (Official smart
                        contract will be provided by our team prior the launch
                        day in our Discord). The smart contract will be produced
                        with the ERC721-A standard, which will allow multiple
                        NFTs to be minted for the equivalent price of a single
                        NFT, which also enables the lowest possible gas fees
                        available on Ethereum blockchain when minting.
                      </div>
                    </div>
                    <div class="acc__card">
                      <div class="acc__title">
                        What Distinguishes The Ethernal Gates NFT From Other NFT
                        Projects?
                      </div>
                      <div class="acc__panel">
                        This NFT drop is not your average drop involving 10,000
                        avatar profile pictures. Arts DAO is collaborating
                        Ethernal Gates NFT raise capital for a fund. The fund
                        will provide purchasers of Ethernal Gates NFT with
                        exposure to a balanced portfolio of the high-growth
                        metaverse and blue-chip NFT projects. In effect, this
                        means that we are creating an actively traded investment
                        fund that will bring economic value to our members, as
                        well as becoming a major global player that can invest
                        in a collection of ‘blue-chip’, emerging and venture NFT
                        / Web3 projects on behalf of members.
                      </div>
                    </div>
                    <div class="acc__card">
                      <div class="acc__title">What’s The Price?</div>
                      <div class="acc__panel">
                        0.49 Eth for members of the whitelist (to be determined
                        in due course), and 0.59 for the public sale. We
                        encourage you to join the Presale Whitelist in order to
                        benefit from this incredible price. Please find more
                        information how to get into a Whitelist on our Discord{" "}
                        <a
                          href="https://discord.gg/ArtsDao"
                          class="fc-primary td-underline"
                        >
                          (link here)
                        </a>
                      </div>
                    </div>
                    <div class="acc__card">
                      <div class="acc__title">When Can I Mint?</div>
                      <div class="acc__panel">
                        The mint date is to be confirmed, it will be announced
                        soon across all our social media, but the best way is to
                        join our Discord, all the necessary information will be
                        there 
                        <a
                          href="https://discord.gg/ArtsDao"
                          class="fc-primary td-underline"
                        >
                          (link here)
                        </a>
                        . 
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-2 hidden-md-down"></div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
export default Faq;
