import Web3 from "web3";
import {
  CONTRACT_ADDRESSES,
  CHAIN_ID,
  INFURA_KEY,
} from "../constants/constants";

import NFTContractABI from "./abis/NFTContract.json";

let web3;
let web3Infura;

const infuraUrl =
  CHAIN_ID === 1
    ? `https://mainnet.infura.io/v3/${INFURA_KEY}`
    : `https://rinkeby.infura.io/v3/${INFURA_KEY}`;

try {
  if (typeof window !== "undefined")
    web3 = new Web3(window?.web3?.currentProvider);
  web3Infura = new Web3(infuraUrl);
} catch (e) {
  console.log("Connect Web3", e);
}

export const setWeb3Provider = (provider) => {
  web3 = new Web3(provider);
};

export const nftContract = () => {
  let contract;
  try {
    if (
      (typeof window !== "undefined" && window?.web3?.currentProvider) ||
      web3
    ) {
      contract = new web3.eth.Contract(
        NFTContractABI,
        CONTRACT_ADDRESSES.nftContract
      );
    } else {
      contract = new web3Infura.eth.Contract(
        NFTContractABI,
        CONTRACT_ADDRESSES.nftContract
      );
    }
    return contract;
  } catch (e) {
    console.log("contract init", e);
  }
};

export const getWeb3 = () => web3;
