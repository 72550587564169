import React, { useEffect, useState } from "react";
import {
  eligibleVIP,
  notEligibleMint3,
  notEligibleVIP,
  walletConnectedMessage,
} from "../hooks/swal2";
import { useToaster } from "../hooks/toaster";
import { useCustomWeb3React } from "../hooks/useCustomWeb3React";
import { useActivateWallet, useEagerConnect } from "../hooks/walletConnect";
import { walletList } from "../utils/web3Connectors";
import { Mint } from "./CTAs/Mint";
// import proofs2 from "../utils/proofs2.json";

export const ConnectModel = () => {
  const activateWallet = useActivateWallet();
  const _ = useEagerConnect();
  const {} = useToaster();
  const [shouldShow, setShouldShow] = useState(false);
  const { account, chainId } = useCustomWeb3React();

  const handlerMetamask = async () => {
    await activateWallet(walletList[0].connector, (e) => {
      walletConnectedMessage(e);
    });
    setShouldShow(true);
  };

  const handlerWalletConnect = async () => {
    await activateWallet(walletList[1].connector, (e) => {
      walletConnectedMessage(e);
    });
    setShouldShow(true);
  };

  return (
    <div class="x-modal hidden">
      <div class="modal-content">
        <button class="close-btn modal-close">X</button>

        <div
          style={{
            // backgroundColor: "red",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
          class="inner-content"
        >
          {account ? (
            <Mint />
          ) : (
            <>
              <h4 class="title">CONNECT WALLET</h4>
              <p class="subtitle">Connect your Wallet</p>

              <button onClick={handlerMetamask} class="wallet-btn">
                <img src="/assets/images/metamask-btn.jpg" />
              </button>

              <button onClick={handlerWalletConnect} class="wallet-btn">
                <img src="/assets/images/connectwallet-btn.jpg" />
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
