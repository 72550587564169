import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useState, useEffect } from "react";
import {
  eligibleVIP,
  maxMinted,
  notEligibleMint3,
  notEligibleVIP,
  notEligibleWL,
} from "../hooks/swal2";
import { useCustomWeb3React } from "../hooks/useCustomWeb3React";
import Countdown from "react-countdown";

// import proofs2 from "../utils/proofs2.json";
// import { mintCount } from "../contracts/functions/nftContract";

// import { Timer } from "./Timer";
function Banner() {
  const [stop, setStop] = useState(false);
  const { account, chainId, deactivate } = useCustomWeb3React();
  const [userMintCount, setUserMintCount] = useState(0);
  const theme = useTheme();
  const _sm = useMediaQuery(theme.breakpoints.down("sm"));

  const endDate = "Sat Jun 05 2022 15:30:00 GMT+0000";

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return (
        <></>
        // <Typography
        //   // variant="h5"
        //   color="white"
        //   style={{ textAlign: "center" }}
        //   textTransform={"uppercase"}
        //   variant={_sm ? "h6" : "h4"}
        //   fontWeight={"bold"}
        // >
        //   Whitelist sale open
        // </Typography>
      );
    } else {
      return (
        <>
          <Typography
            align="center"
            variant={_sm ? "h6" : "h4"}
            fontWeight={"bold"}
            color="white"
            textTransform={"uppercase"}
            marginBottom={_sm ? 2 : 5}
          >
            Public Sale opens in:&nbsp;
          </Typography>
          {/* <Typography align="center" variant="h6" color="white">
            {days} days {hours} hours {minutes} minutes {seconds} seconds
          </Typography> */}
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexWrap: "wrap",
            }}
          >
            {[
              { d: days, title: "DAY(S)" },
              { d: hours, title: "HOUR(S)" },
              { d: minutes, title: "MINUTE(S)" },
              { d: seconds, title: "SECOND(S)" },
            ].map((data, idx) => {
              return (
                <Box
                  // marginLeft={idx && 5}
                  key={idx}
                  // border="1px solid white"

                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    align="center"
                    fontWeight={"bold"}
                    width={_sm ? 70 : 100}
                    height={_sm ? 50 : 80}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: 5,
                      backgroundColor: "#CCAC7B",
                      margin: "0px 8px",
                      marginTop: 10,
                    }}
                    variant={_sm ? "h5" : "h3"}
                    color="white"
                  >
                    {data.d.toString().length == 1 ? "0" + data.d : data.d}
                  </Typography>
                  <Typography
                    align="center"
                    fontWeight={"bold"}
                    variant="h7"
                    style={{
                      fontSize: _sm ? "0.7rem" : undefined,
                    }}
                    color="white"
                    mt={_sm ? 1.5 : 2}
                  >
                    {data.title}
                  </Typography>
                </Box>
              );
            })}
          </Box>
        </>
      );
    }
  };

  return (
    <div>
      {/* <!-- Banner Section --> */}

      <section class="banner_area">
        <div class="container">
          <div class="justify_center">
            {/* <div class="minting_counter_box">
              <p class="counter_title  hide__mob">Minting is open</p>

              <div class="minting_counter"> */}
            {/* <div class="row mr_0 centered_items">
                  <p class="counter_title  hide__dktp">Minting is open</p>
                  <div class="col-md-4 col-nopadd">
                    <p class="price_title">Minting price</p>
                    <p class="m_price">0.4 ETH</p>
                  </div>
                  <div class="col-md-4 col-nopadd">
                    <div class="qty_box">
                      <div class="qty">
                        <span class="minus">-</span>
                        <input
                          type="number"
                          class="count"
                          name="qty"
                          value="1"
                        />
                        <span class="plus">+</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 col-nopadd">
                    <a class="mint_btn" href="#" data-targetmodal="x-modal">
                      <span>Mint Now</span>{" "}
                      <i class="fa fa-chevron-right"></i>
                    </a>
                  </div>
                </div> */}
            {/* </div>
            </div> */}
            {!stop && (
              <>
                <Countdown
                  date={Date.parse(endDate)}
                  renderer={renderer}
                  onComplete={() => setStop(true)}
                />
              </>
            )}
            <div class="banner__content">
              <h1 class="banner__title">ETHERNAL GATES</h1>
              <p class="banner__desc">
                Arts DAO is collaborating with Kristel Bechara in creating the
                Ethernal Gates NFT Drop. The Ethernal Gates signifies the gates
                to the Arts DAO community, which grew organically from like
                minded NFT collectors who began meeting up monthly.
              </p>
              <div class="ta-center">
                {/* { account && !phase3WL ? (
                  <button
                    onClick={() => {
                      handlerWhitelistedMint();
                    }}
                    class="btn btn-primary mbpx-5 tt-uppercase banner-btn"
                    // data-targetmodal="x-modal"
                  >
                    {"Mint Now"}
                  </button>
                ) : (

                )} */}

                {/* <a
                  href="#"
                  style={{ marginTop: 20 }}
                  onClick={() => {
                    document
                      .getElementsByClassName("x-modal")[0]
                      .classList.add("show");
                    document
                      .getElementsByTagName("body")[0]
                      .classList.add("modal-open");
                  }}
                  class="btn btn-primary mbpx-5 tt-uppercase banner-btn"
                  data-targetmodal="x-modal"
                >
                  {"Mint Now"}
                </a> */}

                {/* {userMintCount >= 3 && (
                  <button
                    onClick={() => {
                      maxMinted();
                    }}
                    class="btn btn-primary mbpx-5 tt-uppercase banner-btn"
                    // data-targetmodal="x-modal"
                  >
                    {"Mint Now"}
                  </button>
                )}

                {account && (
                  <a
                    onClick={() => {
                      deactivate();
                      window.location.reload();
                    }}
                    class="btn btn-primary mbpx-5 tt-uppercase banner-btn"
                    // data-targetmodal="x-modal"
                  >
                    {"Disconnect"}
                  </a>
                )} */}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- // Banner Section --> */}
    </div>
  );
}
export default Banner;
