import React, { useState } from "react";

function Contact() {
  const [state, setState] = useState({
    name: "",
    email: "",
    interest: "",
    phoneNumber: "",
    hearAboutUs: "",
    message: "",
  });

  const handlerState = (property) => (e) => {
    // console.log(e.currentTarget.value);
    setState({
      ...state,
      [property]: e.currentTarget.value,
    });
  };
  // console.log(state);
  return (
    <div>
      <div class="sec-padding --small hidden-md-down bg-black"></div>
      <div id="join"></div>
      <section class="contact_sec sec-padding">
        <div class="container">
          <div class="row">
            <div class="col-md-10 col-sm-12 col-nopadd">
              <div class="content_area">
                <div class="row">
                  <div class="col-md-5 col-sm-12 col-nopadd">
                    <h2 class="title">Get in touch</h2>
                    <p class="desc">Send us a Message</p>
                  </div>
                  <div class="col-md-7 col-sm-12 col-nopadd">
                    <form action="/action_page.php">
                      <div class="row">
                        <div class="col-md-6 col-sm-12">
                          <div class="form-group">
                            <input
                              type="name"
                              class="form-control"
                              placeholder="Full Name"
                              id="name"
                              onChange={handlerState("name")}
                              value={state.name}
                            />
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-12">
                          <div class="form-group">
                            <input
                              type="email"
                              class="form-control"
                              placeholder="Email"
                              id="email"
                              onChange={handlerState("email")}
                              value={state.email}
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6 col-sm-12">
                          <div class="form-group">
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Interest"
                              id="interest"
                              onChange={handlerState("interest")}
                              value={state.interest}
                            />
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-12">
                          <div class="form-group">
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Phone Number"
                              id="number"
                              onChange={handlerState("phoneNumber")}
                              value={state.phoneNumber}
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <div class="form-group">
                            <select
                              onChange={handlerState("hearAboutUs")}
                              class="form-control"
                              value={state.hearAboutUs}
                            >
                              <option>How did you hear about us?</option>
                              <option value="Twitter">Twitter</option>
                              <option value="Instagram">Instagram</option>
                              <option value="LinkedIn">LinkedIn</option>
                              <option value="Email">Email</option>
                              <option value="Friends">Friends</option>
                              <option value="Other">Other</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <textarea
                            class="form-control"
                            rows="2"
                            placeholder="Your Message"
                            onChange={handlerState("message")}
                            value={state.message}
                          ></textarea>
                        </div>
                      </div>
                      <div class="text-md-right">
                        <a
                          href={`mailto:alpha@artsdao.io?subject=GET IN TOUCH&body=
						 Full name: ${state.name}%0D%0A
						 Email: ${state.email}%0D%0A
						 Interest: ${state.interest}%0D%0A
						 Phone number: ${state.phoneNumber}%0D%0A
						 How did you hear about us?: ${state.hearAboutUs}%0D%0A
						 Message: ${state.message}%0D%0A
						  `}
                          class="contact_btn"
                        >
                          submit <i class="fa-light fa-arrow-right"></i>
                        </a>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-2 col-nopadd"></div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Contact;
