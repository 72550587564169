import React, { Componet } from "react";
class Meet extends React.Component {
  render() {
    return (
      <div>
        <div id="meet"></div>
        <section class="meet_sec sec-padding bg-black">
          <div class="container">
            <div class="row centered_items">
              <div class="col-md-6 col-sm-12">
                <div class="meet__box">
                  <h2 class="title d-flex d-md-none text-center justify-content-center">
                    Meet the Artist
                  </h2>
                  <img src="assets/images/artist.png" class="styled_img" />
                  <div class="border_box"></div>
                  <div class="artist_social">
                    <ul class="unstyled">
                      <li>
                        <a
                          href="https://www.instagram.com/kristelbechara/"
                          target="_blank"
                        >
                          <img src="assets/images/rightarrowblack.png" />{" "}
                          Instagram
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://twitter.com/atelier_kristel?lang=en"
                          target="_blank"
                        >
                          <img src="assets/images/rightarrowblack.png" />{" "}
                          Twitter
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-sm-12">
                <h2 class="title d-none d-md-flex">Meet the Artist</h2>
                <p class="desc">
                  A pioneer of NFT art in the region, Kristel Bechara, the
                  rising star of the Middle Eastern art world is a Lebanese,
                  Dubai-based, award-winning multimedia artist. Being the first
                  NFT artist in the Middle East to adapt to this technology, she
                  had her drops widely collected on Foundation when she began
                  dropping her work in early 2021 before the NFT market boom.
                </p>
                <a
                  href="https://foundation.app/@KristelBechara"
                  target="_blank"
                  class="btn btn-primary --outline tt-uppercase"
                >
                  visit artist portfolio <i class="fa-light fa-arrow-right"></i>
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
export default Meet;
