import { AddCircleOutline, RemoveCircleOutline } from "@mui/icons-material";
import {
  Box,
  Button,
  ButtonBase,
  IconButton,
  InputAdornment,
  Modal,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  currentPrice,
  mint,
  mintCount,
  presaleMint,
} from "../../contracts/functions/nftContract";
import {
  eligibleVIP,
  maxMinted,
  notEligibleVIP,
  walletConnectedMessage,
} from "../../hooks/swal2";
import { useCustomWeb3React } from "../../hooks/useCustomWeb3React";
import { toEther } from "../../utils/formating";
// import {
//   mintAmountAtom,
//   mintPriceAtom,
//   mintPriceSelector,
//   openMintAtom,
//   selectedWalletAtom,
// } from "@recoil-state";
// import { useWeb3React } from "@web3-react/core";
// import { useEffect, useState } from "react";

// import { useRecoilState, useRecoilValue } from "recoil";
// import {
//   presalePrice,
//   presaleMint,
//   privateSaleMint,
//   whitelistMaxCount,
// } from "src/contracts/functions/nftContract";
// import {
//   notEligibleVIP,
//   switchMessage,
//   transactionMessage,
//   alreadyMinted,
// } from "src/hooks/swal2";
// import { toEther } from "src/utils/formating";
// import whitelisted from "../../utils/whitelisted.json";
import "./mint.css";

export const Mint = () => {
  const { account, chainId } = useCustomWeb3React();

  // const isWhitelisted = proofs[account.toLowerCase()];

  const [mintPrice, setMintPrice] = useState(0);
  // const [userMintCount, setUserMintCount] = useState(0);
  // const [random, setRandom] = useState(Math.random());

  const MIN = 1;
  const MAX = 5;
  // account && isWhitelisted ? proofs[account.toLowerCase()]?.maxCount : 10;

  const [count, setCount] = useState(MIN);

  const handlerMax = () => setCount(MAX);

  const handlerIncremet = () => count < MAX && setCount(count + 1);
  const handlerDecrement = () => count > MIN && setCount(count - 1);

  const handlerMint = () => {
    if (account) {
      mint(count, account, (e) => {
        e && walletConnectedMessage(e);
      });
    }
  };

  const handlerPrice = async () => {
    if (account) {
      setMintPrice(await currentPrice());
    }
  };

  // const handlerMintCount = async () => {
  //   if (account) {
  //     const minted = parseInt((await mintCount(account))?.wlMintCount);
  //     setUserMintCount(minted);
  //     setCount(count > minted ? count - minted : count);
  //   }
  // };

  useEffect(() => {
    handlerPrice();
  }, [account, chainId]);

  return (
    <div>
      {/* <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title-2"
        aria-describedby="modal-modal-description-2"
      > */}
      <Box
        style={{ backgroundColor: "black", maxWidth: 350 }}
        className="mint-container"
      >
        <div
          style={{
            border: "1px solid #CCAC7B",
            minWidth: 300,
            width: "100%",
            // minWidth: 350,
            borderRadius: 10,
          }}
          className="flex flex-col mint-box items-center justify-center"
        >
          <div className="grid grid-cols-3 w-full">
            <div className="flex flex-col items-center justify-center col-start-2">
              <div
                className=" text-xl mb-4"
                style={{
                  // fontFamily: "Joyride-STD",
                  textTransform: "none ",
                  fontWeight: 600,
                  // letterSpacing: 1,
                }}
              >
                {"Mint"}
              </div>
            </div>
            {/* <ButtonBase
              className="flex w-full justify-center items-start ml-4"
              // onClick={handleClose}
            >
              X
            </ButtonBase> */}
          </div>
          <div
            className="w-full"
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div
              className="flex items-center mb-2"
              style={{
                justifyContent: "space-around",
                // backgroundColor: "red",
                maxWidth: 220,
                alignSelf: "center",
              }}
            >
              <IconButton
                size="large"
                // disabled={mintAmount == 1}
                onClick={handlerDecrement}
                style={{ color: "#CCAC7B" }}
                className="pbu"
              >
                <RemoveCircleOutline style={{ fontSize: "1.2rem" }} />
              </IconButton>
              <p style={{ fontSize: "1.2em" }}>{count}</p>
              <IconButton
                // disabled={mintAmount == maxAmount}
                size="large"
                onClick={handlerIncremet}
                style={{ color: "#CCAC7B" }}
                className="pbu"
              >
                <AddCircleOutline style={{ fontSize: "1.2rem" }} />
              </IconButton>

              <Button
                onClick={handlerMax}
                style={{
                  borderColor: "#CCAC7B",
                  color: "#FFFFFF",
                  background: "rgba(204, 172, 123, 0.4)",
                }}
                variant="outlined"
                className="ml-2"
              >
                Max
              </Button>
            </div>
            <p
              className="text-center"
              style={{
                // fontFamily: "OpenSans",
                fontSize: "0.7rem",
                opacity: 0.7,
                textTransform: "uppercase",
              }}
            >
              Max {MAX} per Transaction
            </p>
          </div>
          <TextField
            style={{ color: "white" }}
            label="MINT PRICE"
            value={toEther((parseInt(mintPrice) * count).toString())}
            variant="standard"
            readOnly
            focused
            // disabled
            className="w-24 my-3"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <div
                    style={{
                      color: "white",
                    }}
                  >
                    ETH
                  </div>
                </InputAdornment>
              ),
            }}
          />
          <Button
            onClick={handlerMint}
            className="my-3 w-40"
            style={{ backgroundColor: "#CCAC7B", color: "white" }}
          >
            Mint now
          </Button>
        </div>
      </Box>
      {/* </Modal> */}
    </div>
  );
};

// export default Mint;
