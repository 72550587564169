import React, { Componet } from "react";

class Header extends React.Component {
  render() {
    return (
      <div>
        <div class="mobile-nav">
          <nav>
            <ul class="unstyled">
              <li>
                <a href="#project">The project</a>
              </li>
              <li>
                <a href="#utility">Membership</a>
              </li>
              <li>
                <a href="#meet">Meet the artist</a>
              </li>
              <li>
                <a href="#what-is-artsdao">what is artsdao</a>
              </li>
              <li>
                <a href="#road-map">road map</a>
              </li>
              <li>
                <a
                  href="https://artsdao.notion.site/Whitepaper-d026b5f45632439893d3bebf258ebefa"
                  target={"_blank"}
                >
                  whitepaper
                </a>
              </li>
              <li>
                <a href="#faq">faq</a>
              </li>

              <li>
                <a href="https://discord.gg/ArtsDao" class="joinus-btn">
                  Join us on discord <i class="fa-light fa-arrow-right"></i>
                </a>
              </li>
            </ul>
            <div class="row extra-mobile-menu">
              <div class="col-6">
                <a href="https://twitter.com/arts_dao" class="d-flex">
                  <img src="assets/images/rightup.png" /> Twitter
                </a>
                <a href="https://www.instagram.com/artsdao" class="d-flex">
                  <img src="assets/images/rightup.png" /> Instagram
                </a>
              </div>

              <div class="col-6">
                <a href="https://discord.gg/ArtsDao" class="d-flex">
                  <img src="assets/images/rightup.png" /> Discord
                </a>
                <a
                  href="https://www.linkedin.com/company/artsdao/"
                  class="d-flex"
                >
                  <img src="assets/images/rightup.png" /> LinkedIn
                </a>
              </div>
            </div>
          </nav>
        </div>

        {/* <main class="app-container"> */}

        {/* <!-- Mobile Navigation Button Start--> */}

        {/* <!-- Mobile Navigation Button End--> */}

        <header class="ph">
          <div class="container">
            <div class="row centered_items">
              <div class="col-md-2">
                <a href="/" class="logo">
                  <img src="assets/images/logo.png" class="mtpx-10 mbpx-10" />
                </a>
              </div>
              <div class="col-md-10 col-nopadd d-none d-md-block">
                <nav class="pn">
                  <ul class="unstyled">
                    <li>
                      <a href="#project">The project</a>
                    </li>
                    <li>
                      <a href="#utility">Membership </a>
                    </li>
                    <li>
                      <a href="#meet">Meet the artist</a>
                    </li>
                    <li>
                      <a href="#what-is-artsdao">what is artsdao</a>
                    </li>
                    <li>
                      <a href="#road-map">road map</a>
                    </li>
                    <li>
                      <a
                        href="https://artsdao.notion.site/Whitepaper-d026b5f45632439893d3bebf258ebefa"
                        target={"_blank"}
                      >
                        whitepaper
                      </a>
                    </li>
                    <li>
                      <a href="#faq">faq</a>
                    </li>
                    <li>
                      <a href="https://discord.gg/ArtsDao">
                        Join us on discord{" "}
                        <i class="fa-light fa-arrow-right"></i>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>

              <div class="col-6 d-flex d-md-none">
                <div class="mobile-nav-btn">
                  <img
                    src="assets/images/mobile-menu.svg"
                    alt="*"
                    class="open"
                  />
                  <img
                    src="assets/images/mobile-menu-close.svg"
                    alt="*"
                    class="close"
                  />
                </div>
              </div>
            </div>
          </div>
        </header>
      </div>
    );
  }
}
export default Header;
