import React, { Componet } from "react";

class Artsdao extends React.Component {
  render() {
    return (
      <div>
        <div id="what-is-artsdao"></div>
        <section class="video_banner_main">
          <img src="assets/images/before_img.png" class="sun_before" />
          <div class="video_banner_sec">
            <div class="container">
              <div class="row centered_items">
                <div class="d-md-none d-flex align-items-center">
                  <a href="">
                    <img src="assets/images/videobanner.png" class="m-auto" />
                  </a>
                </div>
                <div class="col-md-6">
                  <h3 class="title">What is artsdao</h3>
                  <p class="desc">
                    Arts DAO represents a community of artists, creators,
                    collectors, developers, project founders and senior
                    futurists who have come together to:
                    <br />
                    <br />
                    <ul
                      style={{
                        listStyle: "initial",
                        marginLeft: 20,
                        lineHeight: "inherit",
                      }}
                    >
                      <li>
                        Collect top-tier and ‘blue-chip’ NFT projects or artists
                        building on the Blockchain.
                      </li>
                      <li>
                        Partner with builders and creators, and offer them
                        grants to help them grow into established Web3 Brands.
                      </li>
                      <li>
                        Hosting private auctions of NFT drops within our
                        ecosystem and community.
                      </li>
                      <li>Hosting monthly exclusive ‘in real-life’ events.</li>
                    </ul>
                  </p>
                  <a
                    href="https://artsdao.io"
                    target="_blank"
                    class="btn btn-primary --outline"
                  >
                    GET TO KNOW US <i class="fa-light fa-arrow-right"></i>
                  </a>
                </div>
                {/* d-none */}
                <div class="col-md-6  d-md-block chepi-video_box">
                  <div class="video_box">
                    <a href="https://vimeo.com/690834759" data-fancybox>
                      <img
                        className="chepi-play"
                        src="assets/images/play-img.png"
                        alt=""
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
export default Artsdao;
