import "./App.css";
import Header from "./Components/Header";
import Banner from "./Components/Banner";
import Project from "./Components/Project";
import Utility from "./Components/Utility";
import Meet from "./Components/Meet";
import Artsdao from "./Components/Artsdao";
import Jedi from "./Components/Jedi";
import Roadmap from "./Components/Roadmap";
import Portfolio from "./Components/Portfolio";
import Newsletter from "./Components/Newsletter";
import Faq from "./Components/Faq";
import Contact from "./Components/Contact";
import Footer from "./Components/Footer";
import { ConnectModel } from "./Components/ConnectModel";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <div>
      <ToastContainer />
      <ConnectModel />
      <Header />
      <Banner />
      <Project />
      <Utility />
      <Meet />
      <Artsdao />
      <Jedi />
      <Roadmap />
      <Portfolio />
      <Newsletter />
      <Faq />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
