import Swal from "sweetalert2";

// import "styles/globals.scss";
// import "sweetalert2/src/sweetalert2.scss";
// import "react-toastify/dist/ReactToastify.css";
// import { setRecoil } from "recoil-nexus";

import { getErrorMessage } from "../utils/walletConnectFunctions";
import { CHAIN_ID } from "../constants/constants";
import { approve } from "../contracts/functions/nftContract";

export const walletConnectedMessage = (e) => {
  if (e)
    Swal.fire({
      icon: "error",
      title: "Aw, Snap!",
      text: getErrorMessage(e),
    });
  // Swal.fire("Congratulations!", "Your wallet has been connected.", "success");
  else
    Swal.fire({
      icon: "success",
      title: "Congratulations!",
      text: "Your wallet has been connected.",
      AllowOutsideClick: false,
      didClose: () => {
        // setRecoil(closeSWALAtom, true);
      },
    });
};

export const transactionMessage = (e) => {
  if (e)
    Swal.fire({
      icon: "error",
      title: "Aw, Snap!",
      text: getErrorMessage(e),
    });
};

export const mintSuccessful = () => {
  Swal.fire({
    icon: "success",
    title: "Congratulations!",
    text: "Mint is Successful",
  });
};

export const mintFailed = () => {
  Swal.fire({
    icon: "error",
    title: "Aw, Snap!",
    text: "Your Transaction Has Failed!",
  });
};

export const maxMinted = () => {
  Swal.fire({
    icon: "info",
    title: "You have already minted your max allocation",
    text: "Please return in public sale!",
  });
};

export const transactionRejected = () => {
  Swal.fire("Aw, Snap!", "Transaction Rejected", "error");
};

export const transactionFailed = () => {
  Swal.fire({
    icon: "error",
    title: "Aw, Snap!",
    text: "An Error Occured!",
  });
};

export const transactionSuccessMint = () => {
  Swal.fire({
    icon: "success",
    title: "Congratulations!",
    text: "Mint Successful",
  });
};

export const transactionSuccess = () => {
  Swal.fire({
    icon: "success",
    title: "Congratulations!",
    text: "Thank you for subscribing!",
  });
};

export const subscribeSuccess = () => {
  Swal.fire({
    icon: "success",
    title: "Congratulations!",
    text: "Thank you for subscribing!",
  });
};

export const subscribeFailed = () => {
  Swal.fire({
    icon: "error",
    title: "Aw, Snap!",
    text: "An Error Occured!",
  });
};

export const switchMessage = (chainId) => {
  if (CHAIN_ID !== chainId) {
    Swal.fire({
      icon: "error",
      title: "Unsupported Network",
      text: `Please switch to Ethereum mainnet to Mint Crypto Bear.`,
    });
    return true;
  }
  return false;
};

export const notEligibleVIP = () => {
  Swal.fire({
    icon: "error",
    title: "Sorry you missed the Whitelist.",
    html: `You are not eligible to mint until Public Sale on 5th June 7:00pm (GST) please come back then.`,
    // html: `Come back for the Public Sale on 22nd Feb at 11:30 PST/ 19:30 GMT<br>
    // See yah then!`,
  });
};

export const notEligibleMint3 = () => {
  Swal.fire({
    icon: "error",
    // title: "Sorry you missed the Whitelist.",
    html: `You are not eligible to mint until Whitelist sale on 4th June 7:00pm (GST) please come back then.`,
    // html: `Come back for the Public Sale on 22nd Feb at 11:30 PST/ 19:30 GMT<br>
    // See yah then!`,
  });
};

// export const notEligibleMint4 = () => {
//   Swal.fire({
//     icon: "error",
//     title: "Sorry you missed the Whitelist.",
//     html: `You are not eligible to mint until Public Sale on 5th June 7:00pm (GST) please come back then.`,
//     // html: `Come back for the Public Sale on 22nd Feb at 11:30 PST/ 19:30 GMT<br>
//     // See yah then!`,
//   });
// };

export const notEligibleWL = () => {
  Swal.fire({
    icon: "error",
    title: "Sorry you missed the Whitelist.",
    html: `You are not eligible to mint until Public Sale on 5th June 7:00pm (GST) please come back then.`,
    // html: `Come back for the Public Sale on 22nd Feb at 11:30 PST/ 19:30 GMT<br>
    // See yah then!`,
  });
};

export const eligibleVIP = () => {
  Swal.fire({
    icon: "success",
    title: "Congratulations!",
    html: `You made the Whitelist!`,
    // html: `Come back for the Public Sale on 22nd Feb at 11:30 PST/ 19:30 GMT<br>
    // See yah then!`,
  });
};

export const alreadyMinted = () => {
  Swal.fire({
    icon: "error",
    title: "Already minted upto allowed count",
    text: `You can now mint in public sale which is on 22nd February`,
  });
};

// export const approveDialog = (account) => {
//   Swal.fire({
//     title: "Are you sure?",
//     text: "You are required to give permission to stake your NFT!",
//     icon: "warning",
//     showCancelButton: true,
//     confirmButtonColor: "#3085d6",
//     cancelButtonColor: "#d33",
//     confirmButtonText: "Yes, Approve it!",
//   }).then((result) => {
//     if (result.isConfirmed) {
//       approve(account, (e) => {
//         if (!e) {
//           Swal.fire("Approved!", "Now you can stake your NFT.", "success");
//         }
//       });
//     }
//   });
// };
