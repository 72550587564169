import { useEffect } from "react";

import { toast } from "react-toastify";
import Swal from "sweetalert2";

import { getErrorMessage } from "../utils/walletConnectFunctions";
import { CHAIN_ID } from "../constants/constants";
import { useCustomWeb3React } from "./useCustomWeb3React";

export const useToaster = () => {
  const { account, chainId } = useCustomWeb3React();

  const fireToast = (method) => {
    toast[method](
      `You are ${method === "error" ? "not" : ""} connected to mainnet`,
      {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: true,
      }
    );
  };

  const walletConnectedMessage = (e) => {
    if (e)
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: getErrorMessage(e),
      });
    else
      Swal.fire(
        "Congratulations!",
        "Your wallet has been connected.",
        "success"
      );
  };

  useEffect(() => {
    if (account) {
      if (chainId !== CHAIN_ID) {
        return fireToast("error");
      }
      fireToast("success");
    }
  }, [chainId, account]);

  useEffect(() => {
    // if (typeof window !== "undefined" && !window?.web3) {
    //   Swal.fire({
    //     icon: "error",
    //     title: "Oops...",
    //     text: "Please install an Ethereum-compatible browser or extension like MetaMask to use this dApp!",
    //   });
    // }
  }, []);

  return {
    account,
    chainId,
    walletConnectedMessage,
  };
};
