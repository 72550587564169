import React, { Componet } from "react";

class Jedi extends React.Component {
  render() {
    return (
      <div>
        <section class="jedi_sec bg-black sec-padding">
          <div class="container">
            <h2 class="title">JEDI KNIGHTS</h2>
            <p class="desc">
              We are a community of artists and NFT collectors based out of
              Dubai who have come
              <br /> together to build an accelerator which will bridge
              traditional creatives into the <br />
              metaverse and the Web 3.0 world.
            </p>
            <div class="jedi_box_wrap clearfix">
              <div class="jedi_box">
                <img src="assets/images/j_img_1.png" />
                <p class="box_title">Anas Bhurtun</p>
                <p class="box_designation">CO-FOUNDER</p>
              </div>

              <div class="jedi_box">
                <img src="assets/images/j_img_2.png" />
                <p class="box_title">Danosch Zahedi</p>
                <p class="box_designation">CO-FOUNDER</p>
              </div>

              <div class="jedi_box">
                <img src="assets/images/j_img_3.png" />
                <p class="box_title">Maria Sultanova</p>
                <p class="box_designation">Technology & Development Lead</p>
              </div>

              <div class="jedi_box">
                <img src="assets/images/j_img_4.png" />
                <p class="box_title">Sam Zuckerbraun</p>
                <p class="box_designation">Head of Strategy</p>
              </div>

              <div class="jedi_box">
                <img src="assets/images/j_img_5.png" />
                <p class="box_title">Rahim Mahtab</p>
                <p class="box_designation">Co-Founder & Head of Product</p>
              </div>
            </div>

            <div class="jedi_box_wrap clearfix">
              <div class="jedi_box">
                <img src="assets/images/j_img_6.png" />
                <p class="box_title">Zayaan Al-Mukhaini</p>
                <p class="box_designation">Community manager</p>
              </div>

              <div class="jedi_box">
                <img src="assets/images/j_img_7.png" />
                <p class="box_title">Jamilya Kairbayeva</p>
                <p class="box_designation">Project Manager</p>
              </div>

              <div class="jedi_box">
                <img src="assets/images/j_img_8.png" />
                <p class="box_title">Aidana Kapen</p>
                <p class="box_designation">Social Media Specialist</p>
              </div>

              {/* <div class="jedi_box">
                <img src="assets/images/j_img_10.png" />
                <p class="box_title">Jayathma Devdhini </p>
                <p class="box_designation">Content creator </p>
              </div> */}

              {/* <div class="jedi_box">
		 		<img src="assets/images/j_img_9.png"/>
		 		<p class="box_title">Bashar Hnidi</p>
		 		<p class="box_designation">Legal Councel </p>
		 	</div> */}
            </div>

            {/* <div class="jedi_box_wrap clearfix">
              {/* <div class="jedi_box">
		 		<img src="assets/images/j_img_10.png"/>
		 		<p class="box_title">Jayathma Devdhini </p>
		 		<p class="box_designation">Content creator </p>
		 	</div> 

              <div class="jedi_box">
                <img src="assets/images/j_img_11.png" />
                <p class="box_title">Nader Bhurtun </p>
                <p class="box_designation">Copywriter </p>
              </div>

              <div class="jedi_box">
                <img src="assets/images/j_img_12.png" />
                <p class="box_title">Vanshika Choudhary</p>
                <p class="box_designation">Copywriter</p>
              </div>

              <div class="jedi_box">
                <img src="assets/images/j_img_13.png" />
                <p class="box_title">Danishver Chohan</p>
                <p class="box_designation">Sales Associate</p>
              </div>
            </div> */}
          </div>
        </section>
      </div>
    );
  }
}
export default Jedi;
