import React, { Componet } from "react";

class Project extends React.Component {
  render() {
    return (
      <div>
        {/* <!-- sec 2 --> */}
        <div class="sec-padding --small hidden-md-down bg-black"></div>
        <div id="project"></div>
        <section class="project_sec sec-padding --xlarge bg-black">
          <div class="container">
            <div class="row">
              <div class="col-md-6 col-sm-12 matchheight">
                <div class="project_video_sec">
                  <div class="project_video_box">
                    <a href="https://vimeo.com/690833663" data-fancybox>
                      <img src="assets/images/play-img.png" alt="" />
                    </a>
                    <p class="video_title">Watch Project video</p>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-sm-12 matchheight">
                <h2 class="title">the project</h2>
                <p class="desc">
                  Arts DAO is collaborating with Kristel Bechara in creating the
                  Ethernal Gates NFT Drop. The Ethernal Gates signifies the
                  gates to the Arts DAO community, which grew organically from
                  like minded NFT collectors who began meeting up monthly.
                </p>
                <p class="desc">
                  The Ethernal Gates represents Kristel’s first generative art
                  project, and is produced in her signature style. Her
                  collections which dropped on foundation in early 2021 before
                  the nft market boom, were praised by prominent collectors, art
                  lovers and the media.
                </p>
                {/* <p  class="desc">
                  Arts DAO aims to redefine asset management and the collection
                  of value in the metaverse by offering holders of the Ethernal
                  Gates NFT fractionalized ownership of blue chip, high-alpha
                  NFT projects and early stage venture investments but also what
                  it means to be part of a community through the social events
                  offered by Arts DAO.
                </p> */}
                {/* <a class="btn btn-primary --outline mbpx-30 mtpx-20 tt-uppercase">
                  Whitepaper <i class="fa-light fa-arrow-right"></i>
                </a> */}
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
export default Project;
