import React, { Componet } from "react";

class Roadmap extends React.Component {
  render() {
    return (
      <div>
        <div id="road-map"></div>
        <section class="roadmap_sec sec-padding bg-black">
          <div class="container">
            <div class="rows">
              <h2 class="title">ARTSDAO Roadmap</h2>
              <p class="desc">
                We are a community of artists and NFT collectors <br />
                based out of Dubai.
              </p>
            </div>
            <div class="row">
              <div class="col-md-6 col-sm-12">
                <div class="img_box">
                  <img src="assets/images/roadmap.png" />
                </div>
              </div>

              <div class="col-md-6 col-sm-12">
                <div class="wrap_ques">
                  <div class="ques_box">
                    <p class="box_title">1-3 Months</p>
                    <ul>
                      <li>NFT research and investment team put in place</li>
                      <li>
                        Start offering whitelisting and various partnerships
                      </li>
                      <li>
                        Start putting together legally compliant DAO structure
                      </li>
                      <li>Launch of exclusive Newsletter</li>
                      <li>
                        Membership in Arts DAO (more details to follow before
                        the drop)
                      </li>
                    </ul>
                  </div>

                  <div class="ques_box">
                    <p class="box_title">3-12 Months</p>
                    <ul>
                      <li>
                        Launch other NFT projects – “diamonds in the rough”
                      </li>
                      <li>Launch Arts DAO community to other countries</li>
                    </ul>
                  </div>

                  {/* <div class="ques_box">
                    <p class="box_title">6-12 Months</p>
                    <ul>
                      <li>
                        Launch other NFT projects – “diamonds in the rough”
                      </li>
                      <li>Launch Arts DAO community to other countries</li>
                    </ul>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
export default Roadmap;
