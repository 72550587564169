export const CHAIN_ID = 1;

export const CONTRACT_ADDRESSES =
  CHAIN_ID === 1
    ? {
        nftContract: "0x95ABE4b6A019Ca386671F2F1865A319cB3b3ca94",
      }
    : {
        nftContract: "0xeb3b786a4c7ddbe8351523322795b32019a19e64",
        // nftContract: "0x02F000FA987e10eb72D6A20d037A41e979d926b8",
      };

export const INFURA_KEY = "107f7cad99f9495eaf2eb215504186ad";

export const MAX_UINT_256 =
  "115792089237316195423570985008687907853269984665640564039457584007913129639935";
