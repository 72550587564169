import React, {Componet} from "react";

class Portfolio extends React.Component{
    render(){
        return(
            <div>
                <section class="portfolio_sec bg-black sec-padding">
	<div class="container">
		<div class="row centered_items">
			<div class="col-md-8 col-sm-12">
				<h2 class="title">ETHERNAL GATES NFT COLLECTION</h2>
			</div>
			<div class="col-md-4 col-sm-12 d-none d-md-block">
				<div class="ta-right">
					<a class="btn btn-primary --outline tt-uppercase">View all <i class="fa-light fa-arrow-right"></i></a>
				</div>
			</div>
		</div>

		<div class="row">
			<div class="portfolio-slider">
				<div class="slide">
					<img src="assets/images/slide_img_1.jpg"/>
				</div>

				<div class="slide">
					<img src="assets/images/slide_img_2.jpg"/>
				</div>

				<div class="slide">
					<img src="assets/images/slide_img_3.jpg"/>
				</div>

				<div class="slide">
					<img src="assets/images/slide_img_4.jpg"/>
				</div>

				<div class="slide">
					<img src="assets/images/slide_img_5.jpg"/>
				</div>

				<div class="slide">
					<img src="assets/images/slide_img_6.jpg"/>
				</div>

				<div class="slide">
					<img src="assets/images/slide_img_7.jpg"/>
				</div>

				<div class="slide">
					<img src="assets/images/slide_img_8.jpg"/>
				</div>

				<div class="slide">
					<img src="assets/images/slide_img_9.jpg"/>
				</div>

				<div class="slide">
					<img src="assets/images/slide_img_10.jpg"/>
				</div>

				<div class="slide">
					<img src="assets/images/slide_img_11.jpg"/>
				</div>

				<div class="slide">
					<img src="assets/images/slide_img_12.jpg"/>
				</div>

				<div class="slide">
					<img src="assets/images/slide_img_13.jpg"/>
				</div>

				<div class="slide">
					<img src="assets/images/slide_img_14.jpg"/>
				</div>

				<div class="slide">
					<img src="assets/images/slide_img_15.jpg"/>
				</div>
			</div>
		</div>

		<div class="ta-center d-flex d-md-none align-items-center justify-content-center mt-10">
				<a class="btn btn-primary --outline tt-uppercase">View all <i class="fa-light fa-arrow-right"></i></a>
			</div>
	</div>
</section>
            </div>
        )
    }
}
export default Portfolio