import React from "react";

const Footer = () => {
  return (
    <div>
      <footer class="bg-black">
        <div class="container">
          <div class="row">
            <div class="col-md-2 hidden-md-down"></div>
            <div class="col-md-8 col-sm-12 col-nopadd">
              <div class="footer_area sec-padding --small">
                <div class="container">
                  <div class="row">
                    <div class="col-md-3 order-last order-md-first">
                      <a href="#" class="footer-logo">
                        <img src="assets/images/footerlogo.png" />
                        <p class="f_txt d-block d-md-none fc-white mt-4">
                          2022, All Rights Reserved
                        </p>
                      </a>
                    </div>

                    <div class="col-md-1 hidden-md-down"></div>
                    <div class="col-md-8">
                      <div class="row centered_items">
                        <div class="col-md-5">
                          <p class="f_social_title">Follow us </p>
                        </div>
                        <div class="col-md-7">
                          <div class="footer__list">
                            <ul class="unstyled">
                              <li>
                                <a
                                  href="https://twitter.com/arts_dao"
                                  target="_blank"
                                >
                                  <img src="assets/images/rightup.png" />{" "}
                                  Twitter
                                </a>
                              </li>
                              <li>
                                <a
                                  href="https://discord.gg/ArtsDao"
                                  target="_blank"
                                >
                                  <img src="assets/images/rightup.png" />{" "}
                                  Discord
                                </a>
                              </li>
                              <li>
                                <a
                                  href="https://opensea.io/collection/ethernal-gates"
                                  target="_blank"
                                >
                                  <img src="assets/images/rightup.png" />{" "}
                                  Opensea
                                </a>
                              </li>
                              <li>
                                <a
                                  href="https://www.instagram.com/artsdao"
                                  target="_blank"
                                >
                                  <img src="assets/images/rightup.png" />{" "}
                                  Instagram
                                </a>
                              </li>
                              <li>
                                <a
                                  href="https://www.linkedin.com/company/artsdao/"
                                  target="_blank"
                                >
                                  <img src="assets/images/rightup.png" />{" "}
                                  Linkedin
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="bottom__footer">
                <div class="container">
                  <div class="row">
                    <div class="col-md-6 col-sm-12">
                      <ul class="unstyled">
                        <li>
                          <a href="#" class="f_txt">
                            Privacy and Policy
                          </a>
                        </li>
                        <li>
                          <a href="#" class="f_txt">
                            Cookies
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div class="col-md-6 col-sm-12">
                      <p class="f_txt d-none d-md-block">
                        2022, All Rights Reserved
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-2 hidden-md-down"></div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
