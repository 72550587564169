import React, { Componet } from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe";
// import "sweetalert2/src/sweetalert2.scss";
import { subscribeSuccess, subscribeFailed } from "../hooks/swal2";
function Newsletter() {
  const url = `https://artsdao.us20.list-manage.com/subscribe/post?u=aa44ebfd10ef8d400b1763038&id=1047ba5121`;
  return (
    <div>
      <section class="newsletter__sec bg-black sec-padding --xlarge">
        <div class="container">
          <div class="newsletter_box">
            <div class="row centered_items">
              <div class="col-md-5 col-sm-12">
                <h2 class="title">Subscribe to our newsletter</h2>
                <p class="desc">
                  We’ll send you the best of our blog just once a month. We
                  promise.
                </p>
              </div>
              <div class="col-md-1 hidden-md-down"></div>
              <div class="col-md-6 col-sm-12">
                <div class="form_box">
                  <MailchimpSubscribe
                    url={url}
                    render={({ subscribe, status, message }) => (
                      <div>
                        <form
                          onSubmit={(e) => {
                            e.preventDefault();
                            // console.log();
                            subscribe({
                              MERGE0: e.currentTarget[0].value,
                            });
                            document.getElementById("email").value = "";
                          }}
                        >
                          <input
                            id="email"
                            style={{ fontSize: "1rem" }}
                            type="email"
                            name="b_email"
                            placeholder="Enter your Email"
                            class="fc-white"
                          />

                          <input
                            type="submit"
                            value="Subscribe"
                            class="newsletter_btn"
                          />
                        </form>
                        {/* {status === "sending" && (
                          <div style={{ color: "blue" }}>sending...</div>
                        )} */}
                        {
                          status === "error" && subscribeFailed()

                          // <div
                          //   style={{ color: "red" }}
                          //   dangerouslySetInnerHTML={{ __html: message }}
                          // />
                        }
                        {
                          status === "success" && subscribeSuccess()
                          // <div style={{ color: "green" }}>Subscribed !</div>
                        }
                      </div>
                    )}
                  />
                  {/* <input
                      type="email"
                      name="email"
                      placeholder="Enter your Email"
                      class="fc-white"
                    />
                    <a
                      href="http://eepurl.com/hX70O5"
                      target={"_blank"}
                      class="newsletter_btn"
                    >
                      Subscribe
                    </a>
                <input
                      type="submit"
                      value="Subscribe"
                      class="newsletter_btn"
                    /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Newsletter;
