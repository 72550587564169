import { getWeb3, nftContract } from "../getContract";
import {
  transactionRejected,
  transactionSuccessMint,
  transactionFailed,
} from "../../hooks/swal2";
import { toEther } from "../../utils/formating";

export const mint = async (count, account, callBack = (e) => {}) => {
  try {
    let txHash;
    // console.log(count, account, 590000000000000000 * parseInt(count));
    const contract = nftContract();
    await contract?.methods
      .publicMint(count)
      .send({
        from: account,
        value: 590000000000000000 * parseInt(count),
      })
      .on("transactionHash", (hash) => {
        txHash = hash;
      })
      .then((receipt) => {
        transactionSuccessMint();
      })
      .catch((e) => {
        if (e.code === 4001) {
          transactionRejected();
        } else if (e?.message?.includes("not mined within 50 blocks")) {
          const web3 = getWeb3();
          if (web3) {
            const handle = setInterval(() => {
              web3.eth.getTransactionReceipt(txHash).then((res) => {
                if (res != null && res.blockNumber > 0) {
                  clearInterval(handle);
                  if (res.status) {
                    transactionSuccessMint();
                  } else {
                    transactionFailed();
                  }
                }
              });
            });
          }
        } else {
          transactionFailed();
        }
      });
    callBack(undefined);
  } catch (e) {
    console.log("mint", e);
    callBack(e);
  }
};

export const presaleMint = async (
  proof,
  maxCount,
  count,
  amount,
  account,
  callBack = (e) => {}
) => {
  console.log(proof, maxCount, count, amount, account);
  try {
    let txHash;

    const contract = nftContract();
    await contract?.methods
      .presaleMint(proof, maxCount, count)
      .send({
        from: account,
        value: 590000000000000000 * parseInt(count),
        // gas: 214021,
      })
      .on("transactionHash", (hash) => {
        txHash = hash;
      })
      .then((receipt) => {
        transactionSuccessMint();
      })
      .catch((e) => {
        if (e.code === 4001) {
          transactionRejected();
        } else if (e?.message?.includes("not mined within 50 blocks")) {
          const web3 = getWeb3();
          if (web3) {
            const handle = setInterval(() => {
              web3.eth.getTransactionReceipt(txHash).then((res) => {
                if (res != null && res.blockNumber > 0) {
                  clearInterval(handle);
                  if (res.status) {
                    transactionSuccessMint();
                  } else {
                    transactionFailed();
                  }
                }
              });
            });
          }
        } else {
          transactionFailed();
        }
      });
    callBack(undefined);
  } catch (e) {
    console.log("presaleMint", e);
    callBack(e);
  }
};

// ==================================== Price

export const currentPrice = async () => {
  try {
    const contract = nftContract();
    return await contract?.methods.publicPrice().call();
  } catch (e) {
    console.log("currentPrice", e);
    return "0";
  }
};

export const mintCount = async (account) => {
  try {
    const contract = nftContract();
    return await contract?.methods.mintCounts(account).call();
  } catch (e) {
    console.log("mintCount", e);
    return "0";
  }
};

// .once("sending", function (payload) {})
// .once("sent", function (payload) {})
// .once("transactionHash", function (hash) {
//   txHash = hash;
// })
// .once("receipt", function (receipt) {})
// .on("confirmation", function (confNumber, receipt, latestBlockHash) {})
// .on("error", function (error) {
//   console.log(error);
//   if (error.code === 4001) {
//     transactionRejected();
//   } else {
//     transactionFailed();
//   }
// })
// .then(function (receipt) {
//   // will be fired once the receipt is mined
//   console.log(receipt);
//   receipt.status
//     ? transactionSuccess({ msg: "Transaction is Successful" })
//     : transactionFailed();
//   // transactionSuccess({ msg: "Transaction is Successful" });
// });

// export const allowance = async (account) => {
//   try {
//     const contract = nftContract();
//     return await contract?.methods
//       .isApprovedForAll(account, CONTRACT_ADDRESSES.staking)
//       .call();
//   } catch (e) {
//     console.log("isApprovedForAll", e);
//     return false;
//   }
// };

// export const approve = async (account, callBack = (e) => {}) => {
//   try {
//     let txHash;

//     const contract = nftContract();
//     await contract?.methods
//       .setApprovalForAll(CONTRACT_ADDRESSES.staking, true)
//       .send({
//         from: account,
//       })
//       .on("transactionHash", function (hash) {
//         txHash = hash;
//       })
//       .on("confirmation", function (confirmationNumber, receipt) {
//         if (confirmationNumber == 1) {
//           receipt.status == true ? transactionSuccessMint() : transactionFailed();
//         }
//       })
//       .on("error", function (error, receipt) {
//         if (error.code === 4001) {
//           transactionRejected();
//         } else {
//           transactionFailed();
//         }
//       });
//     callBack(undefined);
//   } catch (e) {
//     console.log("setApprovalForAll", e);
//     callBack(e);
//   }
// };

// // ========================================== Mint

// export const mint = async (count, amount, account, callBack = (e) => {}) => {
//   try {
//     const contract = nftContract();
//     await contract?.methods.mint(count).send({
//       from: account,
//       value: amount,
//     });
//     callBack(undefined);
//   } catch (e) {
//     console.log("mint", e);
//     callBack(e);
//   }
// };

// // vip
// export const privateSaleMint = async (count, account, callBack = (e) => {}) => {
//   try {
//     const contract = nftContract();
//     await contract?.methods.privateSaleMint(count).send({
//       from: account,
//     });
//     callBack(undefined);
//   } catch (e) {
//     console.log("privateSaleMint", e);
//     callBack(e);
//   }
// };

// export const presaleMint = async (
//   _proof,
//   _allowedCount,
//   count,
//   amount,
//   account,
//   callBack = (e) => {}
// ) => {
//   try {
//     let txHash;
//     setRecoil(mintAtom, true);
//     setRecoil(openMintAtom, false);
//     const contract = nftContract();
//     await contract?.methods
//       .presaleMint(_proof, _allowedCount, count)
//       .send({
//         from: account,
//         value: amount,
//       })
//       .on("transactionHash", function (hash) {
//         txHash = hash;
//       })
//       .on("confirmation", function (confirmationNumber, receipt) {
//         if (confirmationNumber == 1) {
//           receipt.status == true ? mintSuccessful() : mintFailed();
//           setRecoil(mintAtom, false);
//         }
//       })
//       .on("error", function (error, receipt) {
//         if (error.code === 4001) {
//           transactionRejected();
//         } else {
//           mintFailed();
//         }
//         setRecoil(mintAtom, false);
//       });
//     callBack(undefined);
//   } catch (e) {
//     console.log("presaleMint", e);
//     callBack(e);
//   }
// };

// // ==================================== Price

// export const currentPrice = async () => {
//   try {
//     const contract = nftContract();
//     return await contract?.methods.dutchAuction().call();
//   } catch (e) {
//     console.log("currentPrice", e);
//     return "0";
//   }
// };

// export const presalePrice = async () => {
//   try {
//     const contract = nftContract();
//     return await contract?.methods.PRESALE_PRICE().call();
//   } catch (e) {
//     console.log("PRESALE_PRICE", e);
//     return "0";
//   }
// };

// // ==================================== For Dynamic Max Count
// export const whitelistMaxCount = async (account) => {
//   try {
//     const contract = nftContract();
//     return await contract?.methods.preSaleMintCount(account).call();
//   } catch (e) {
//     console.log("Private sale mint count", e);
//     return "0";
//   }
// };
