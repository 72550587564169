import React, { Componet } from "react";

class Utility extends React.Component {
  render() {
    return (
      <div>
        <div id="utility"></div>
        <section class="utility_sec bg-black">
          <div class="container">
            <h2 class="title">BENEFITS OF THE MEMBERSHIP</h2>
            <p class="desc">
              {/* We are a community of artists and NFT collectors based out of
              Dubai who have come <br />
              together to build a NFT fund */}
            </p>

            <div id="adjust-b" class="row" style={{ marginTop: "100px" }}>
              <div
                class="col-md-4"
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  color: "white",
                  marginBottom: 50,
                }}
              >
                <div
                  style={{
                    height: 120,
                    width: 120,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#1f1d1a",
                    borderRadius: "50%",
                    marginBottom: 20,
                  }}
                  class="icon_box"
                >
                  <img src="assets/images/icon3.png" />
                </div>
                <p
                  class="list_title"
                  style={{
                    fontSize: "1.2rem",
                    fontWeight: 600,
                    marginBottom: 10,
                  }}
                >
                  The DAO
                </p>
                <p
                  class="list_desc"
                  style={{ fontSize: "1rem", textAlign: "center" }}
                >
                  - NFTs and Collectables
                  <br />- Seed Investments <br />- Accelerator
                </p>

                <a
                  style={{ marginTop: 20, fontSize: "0.9rem" }}
                  href="https://artsdao.io/the-dao/"
                  target="_blank"
                  class="btn btn-primary --outline"
                >
                  READ MORE <i class="fa-light fa-arrow-right"></i>
                </a>
              </div>

              <div
                class="col-md-4"
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  color: "white",
                  marginBottom: 50,
                }}
              >
                <div
                  style={{
                    height: 120,
                    width: 120,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#1f1d1a",
                    borderRadius: "50%",
                    marginBottom: 20,
                  }}
                  class="icon_box"
                >
                  <img src="assets/images/icon5.png" />
                </div>
                <p
                  class="list_title"
                  style={{
                    fontSize: "1.2rem",
                    fontWeight: 600,
                    marginBottom: 10,
                  }}
                >
                  Premium NFT Events
                </p>
                <p
                  class="list_desc"
                  style={{ fontSize: "1rem", textAlign: "center" }}
                >
                  Access to Dubai’s most premium NFT community of entrepreneurs,
                  artists, collectors and investors through events in Dubai’s
                  most exclusive locations
                </p>
                <a
                  style={{ marginTop: 20, fontSize: "0.9rem" }}
                  href="https://artsdao.io/access-to-premium-nft-events/"
                  target="_blank"
                  class="btn btn-primary --outline"
                >
                  READ MORE <i class="fa-light fa-arrow-right"></i>
                </a>
              </div>

              <div
                class="col-md-4"
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  color: "white",
                }}
              >
                <div
                  style={{
                    height: 120,
                    width: 120,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#1f1d1a",
                    borderRadius: "50%",
                    marginBottom: 20,
                  }}
                  class="icon_box"
                >
                  <img src="assets/images/icon4.png" />
                </div>
                <p
                  class="list_title"
                  style={{
                    fontSize: "1.2rem",
                    fontWeight: 600,
                    marginBottom: 10,
                  }}
                >
                  Arts DAO Alpha
                </p>
                <p
                  class="list_desc"
                  style={{ fontSize: "1rem", textAlign: "center" }}
                >
                  - Market Insights <br />
                  - Calendars
                  <br />- Whitelist Opportunities
                </p>
                <a
                  style={{ marginTop: 20, fontSize: "0.9rem" }}
                  href="https://artsdao.io/arts-dao-alpha/"
                  target="_blank"
                  class="btn btn-primary --outline"
                >
                  READ MORE <i class="fa-light fa-arrow-right"></i>
                </a>
              </div>
            </div>

            <div id="adjust-b" class="row">
              <div
                class="col-md-4"
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  color: "white",
                  marginBottom: 50,
                }}
              >
                <div
                  style={{
                    height: 120,
                    width: 120,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#1f1d1a",
                    borderRadius: "50%",
                    marginBottom: 20,
                  }}
                  class="icon_box"
                >
                  <img src="assets/images/icon2.png" />
                </div>
                <p
                  class="list_title"
                  style={{
                    fontSize: "1.2rem",
                    fontWeight: 600,
                    marginBottom: 10,
                  }}
                >
                  Access to a gated high-alpha discord
                </p>
                <p
                  class="list_desc"
                  style={{ fontSize: "1rem", textAlign: "center" }}
                >
                  Members-only Discord channel with whitelisting opportunities
                  to other projects for our NFT holders
                </p>
              </div>

              <div
                class="col-md-4"
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  color: "white",
                  marginBottom: 50,
                }}
              >
                <div
                  style={{
                    height: 120,
                    width: 120,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#1f1d1a",
                    borderRadius: "50%",
                    marginBottom: 20,
                  }}
                  class="icon_box"
                >
                  <img src="assets/images/icon7.png" />
                </div>
                <p
                  class="list_title"
                  style={{
                    fontSize: "1.2rem",
                    fontWeight: 600,
                    marginBottom: 10,
                  }}
                >
                  Physically Printed NFT
                </p>
                <p
                  class="list_desc"
                  style={{ fontSize: "1rem", textAlign: "center" }}
                >
                  Every month, holders of the NFT will be entitled to
                  participate in a raffle one person will have their NFT
                  physically printed in acrylic and shipped to the member
                </p>
              </div>

              <div
                class="col-md-4"
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  color: "white",
                }}
              >
                <div
                  style={{
                    height: 120,
                    width: 120,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#1f1d1a",
                    borderRadius: "50%",
                    marginBottom: 20,
                  }}
                  class="icon_box"
                >
                  <img src="assets/images/icon6.png" />
                </div>
                <p
                  class="list_title"
                  style={{
                    fontSize: "1.2rem",
                    fontWeight: 600,
                    marginBottom: 10,
                  }}
                >
                  VIP Tickets & Discounts
                </p>
                <p
                  class="list_desc"
                  style={{ fontSize: "1rem", textAlign: "center" }}
                >
                  Get discounts for other NFT events, with a VIP ticket raffled
                  amongst holders
                </p>
              </div>
            </div>

            <div class="row">
              {/* <div class="col-md-6">
				<div class="Utility_list clearfix">
					<div class="left_sec">
						<div class="icon_box">
							<img src="assets/images/icon1.png"/>
						</div>
					</div>
					<div class="right_sec">
						<p class="list_title">NFT Fund</p>
						<p class="list_desc">Get exposure to an actively managed and traded NFT fund (with investments in avatar projects, flipping NFTs, blue chips and seed  stage investments)</p>
					</div>
				</div>
			</div>  */}
              {/* <div class="col-md-6">
                <div class="Utility_list clearfix">
                  <div class="left_sec">
                    <div class="icon_box">
                      <img src="assets/images/icon5.png" />
                    </div>
                  </div>
                  <div class="right_sec">
                    <p class="list_title">Premium NFT Community Events</p>
                    <p class="list_desc">
                      Access to Dubai’s most premium NFT community of
                      entrepreneurs, artists, collectors and investors through
                      events in Dubai’s most exclusive locations
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="Utility_list clearfix">
                  <div class="left_sec">
                    <div class="icon_box">
                      <img src="assets/images/icon3.png" />
                    </div>
                  </div>
                  <div class="right_sec">
                    <p class="list_title">The DAO</p>
                    <p class="list_desc">
                      - NFTs and Collectables
                      <br />- Seed Investments / Accelerator
                    </p>
                  </div>
                </div>
              </div> */}
            </div>

            {/* <div class="row">
              <div class="col-md-6">
                <div class="Utility_list clearfix">
                  <div class="left_sec">
                    <div class="icon_box">
                      <img src="assets/images/icon7.png" />
                    </div>
                  </div>
                  <div class="right_sec">
                    <p class="list_title">Physically Printed NFT</p>
                    <p class="list_desc">
                      Every month, holders of the NFT will be entitled to
                      participate in a raffle one person will have their NFT
                      physically printed in acrylic and shipped to the member
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <div class="Utility_list clearfix">
                  <div class="left_sec">
                    <div class="icon_box">
                      <img src="assets/images/icon6.png" />
                    </div>
                  </div>
                  <div class="right_sec">
                    <p class="list_title">VIP Tickets & Discounts</p>
                    <p class="list_desc">
                      Get discounts for other NFT events, with a VIP ticket
                      raffled amongst holders
                    </p>
                  </div>
                </div>
              </div>
            </div> */}

            <div
              class="row"
              // style={{ alignItems: "center", justifyContent: "center" }}
            >
              {/* <div class="col-md-6">
                <div class="Utility_list clearfix">
                  <div class="left_sec">
                    <div class="icon_box">
                      <img src="assets/images/icon4.png" />
                    </div>
                  </div>
                  <div class="right_sec">
                    <p class="list_title">Arts DAO Alpha</p>
                    <p class="list_desc">
                      - Market Insights <br />
                      - Calendars
                      <br />- Whitelist Opportunities
                    </p>
                  </div>
                </div>
              </div> */}

              {/* <div class="col-md-6">
                <div class="Utility_list clearfix">
                  <div class="left_sec">
                    <div class="icon_box">
                      <img src="assets/images/icon2.png" />
                    </div>
                  </div>
                  <div class="right_sec">
                    <p class="list_title">
                      Access to a gated high-alpha discord
                    </p>
                    <p class="list_desc">
                      Members-only Discord channel with whitelisting
                      opportunities to other projects for our NFT holders
                    </p>
                  </div>
                </div>
              </div> */}
            </div>
            <div class="row">
              {/* <div class="col-md-6">
                <div class="Utility_list clearfix">
                  <div class="left_sec">
                    <div class="icon_box">
                      <img src="assets/images/icon4.png" />
                    </div>
                  </div>
                  <div class="right_sec">
                    <p class="list_title">VIP Tickets & Discounts</p>
                    <p class="list_desc">
                      Get discounts for other NFT events, with a VIP ticket
                      raffled amongst holders
                    </p>
                  </div>
                </div>
              </div> */}
              {/* <div class="col-md-6">
				
				<div class="Utility_list clearfix">
					<div class="left_sec">
						<div class="icon_box">
							<img src="assets/images/icon8.png"/>
						</div>
					</div>
					<div class="right_sec">
						<p class="list_title">Profit Sharing</p>
						<p class="list_desc">Profits made through the purchase and sales of our assets under management will be redistributed to holders of the NFT</p>
					</div>
				</div>

			</div> */}
            </div>
          </div>
        </section>
      </div>
    );
  }
}
export default Utility;
